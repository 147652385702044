import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContactForm from '../components/ContactForm'
import Img from "gatsby-image";
import HowItWorks from "../components/HowItWorks";
import AgentvsSellFastSactable from '../components/AgentvsSellFastSacTable'
import SeeTheDifferenceSidebar from '../components/SeeTheDifferenceSidebar'
import arrowimg from '../img/down-arrow.png'


const Home = () => {

  return (
    <Layout headerTransparent={false}>
      <SEO
        title={'Get a Cash Offer for Your House in Sacramento | 916-237-9457'}
        description={'Need to sell your house in Sacramento? Get a cash offer from our team and close in as little as 7 days no matter the condition of the property.'}
        keywords={''}
      />






      <section className="py-16 section-stripe-bg bg-gray-50 ">
        <div className="flex flex-wrap px-6 md:px-8 container mx-auto ">
          <div className="w-full md:w-2/3 md:pr-4 prose">



            <h2>“I Need To Sell My House Fast In Sacramento!”</h2>
            <h3>We Buy Houses Anywhere In Sacramento And Other Parts of CA, And At Any Price. <a href="/how-we-buy-houses/">Check Out How Our Process Works.</a> We’re Ready To Give You A Fair Offer For Your House.</h3>
            <p>If you want to<strong> sell your Sacramento house</strong>… we’re ready to give you a fair all-cash offer.</p>
            <p>Stop the frustration of your unwanted property. Let us buy your CA house now, regardless of condition.</p>
            <p><a href="/avoiding-foreclosure/">Avoiding foreclosure</a>? Facing divorce? <strong>Moving</strong>? <strong>Upside down</strong> in your mortgage? <strong>Liens</strong>? It doesn’t matter whether you live in it, you’re renting it out, it’s vacant, or not even habitable. We help owners who have inherited an unwanted property, own a vacant house, are behind on payments, owe liens, downsized and can’t sell… even if the house needs repairs that you can’t pay for… and yes, even if the house is fire damaged or has bad rental tenants.</p>
            <p>Basically, if you have a property and need to sell it… we’d like to make you a fair cash offer and close on it when you’re ready to sell.</p>
            <h2>Do You Need To Sell Your House in Sacramento, CA?</h2>
            <p></p>

            {/* <figure  class="wp-caption alignright"><img src="https://cdn.carrot.com/uploads/sell-your-house-fast.jpg" alt="We can buy your CA house. Contact us today!" width="249"><figcaption class="wp-caption-text">We can buy your CA house.<br>Contact us today!</figcaption></figure> */}


            <strong>Even if an agent can’t sell your house, we can help.</strong> (Sometimes selling a house through a real estate agent is not for everyone.)<p></p>
            <p>And as a bonus…</p>
            <ul>
              <li>you don’t need to clean up and repair the property</li>
              <li>don’t waste time finding an agent who you trust and who can deliver on their promise of selling your house quickly</li>
              <li>you won’t need to sign a contract that binds you to an agent for a certain term</li>
              <li>or deal with the paperwork and the waiting and wondering (and hoping)</li>
            </ul>
            <p><strong>We’ll know very quickly if we can help you</strong>, and unlike selling through an agent, you don’t have to wait to see if the buyer can get financing… <strong>we’re ready to buy right now!</strong></p>
            <p>All that hassle can add stress, months to the process, and in the end after paying the agent’s expensive fees, you may or may not be ahead of the game.</p>
            <p>We work differently at Sell Your Home For Cash Sacramento. When you contact us and submit the short property information form (below), we’ll <strong>give you a fair all-cash offer on your house within 24 hours</strong>… and the best part is: <strong>we can close whenever YOU choose to close</strong> – it’s entirely up to you. It doesn’t matter what condition the house is in, or even if there are tenants in there that you can’t get rid of… don’t worry about it. We’ll take care of it for you. And if you need the cash quickly, we can close in as little as 7 days because we buy houses with cash and don’t have to rely on traditional bank financing. (<a href="/how-we-buy-houses/">Go here to learn about our process →</a>)</p>
            <p>In short…</p>
            <p>No matter what <span>condition</span> your house is in; no matter what <span>situation</span> or <span>timeframe you’re facing…&nbsp;</span></p>
            <p><strong>Our goal is to help make your life easier and get you out from under the&nbsp;property that’s</strong> stressing you out…&nbsp;while still paying a fast, fair, and honest price for your house.</p>
            <p>
            </p>
            <p>We help property owners just like you, in all kinds of situations. From divorce, foreclosure, death of a family member, burdensome rental property, and all kinds of other situations. <span>We buy houses in CA… including Sacramento&nbsp;and surrounding areas and pay a fair cash price, fast.</span>&nbsp;Sometimes, those who own property simply have lives that are too busy to take the time to do all of things that typically need to be done to prepare a&nbsp;house to sell on the market… if that&nbsp;describes you, just&nbsp;let us know about&nbsp;the&nbsp;property you’d like to&nbsp;be rid of and&nbsp;<strong>sell your house fast for cash</strong>.</p>
            <p><span>If you simply don’t want to put up with the hassle of owning that house any longer, and if you don’t want to put up with the hassle and time-consuming expense of selling your property the traditional way, let us know about the property you’d like to be rid of and sell your house fast for cash.</span> Talk to someone in our office before submitting your property information by&nbsp;<strong>calling us today at&nbsp;<strong>916-237-9457</strong></strong></p>
            <p><span><em><strong>We buy houses in Sacramento, CA 95817</strong>&nbsp;and all surrounding areas in CA. If you need to sell your house fast in CA, connect with us… we’d love to make you a fair no-obligation no-hassle offer. Take it or leave it. You’ve got nothing to lose 🙂</em></span></p>


          </div>
          <div className="w-full md:w-1/3 md:pl-4">



            <div className="rounded-xl mb-2 shadow bg-white  mt-6 md:mt-0">
              <div className="px-8 pt-8">
                <h2 className="text-gray-800 font-bold text-2xl md:text-3xl leading-8">Get Your Fair Cash Offer: Start Below!</h2>
                <p class="text-gray-600 mb-2 leading-6 mt-1">We buy houses in any condition -- no commissions or fees and no obligation whatsoever. Start below by giving us a bit of information about your property or call 916-237-9457.</p>
              </div>


              <div className="p-8 pt-4"><ContactForm stacked={true} hideLabels={true} showPlaceholders={true} /></div>

            </div>
            <SeeTheDifferenceSidebar />
          </div>
        </div>
      </section>




    </Layout>
  );
};


export default Home;

